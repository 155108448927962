<!--
     EDGAR | Company Search Results  Component - uses searched Ticker

    <v-container class="fill-height" :key="this.symbol" > 

    iframe url using CIK Number-> https://www.sec.gov/edgar/browse/?CIK=1326160
-->
<template>
    <v-container class="fill-height" :key="this.symbol" > 
        <v-layout justify-center align-center>    <!-- min-width="225" width="550" max-width="600" -->    
            <v-card class="mx-auto my-auto" height="100%" width="100%" hover >
                <v-card-text  class="text--primary ma-0 pa-0">
                    <v-container fill-height class="ma-0 pa-0" >
                        <v-flex fill-height >  
                            <!-- *** feed display section - all rss data will be displayed in the RssFeedViewer *** -->  
                            <!-- height adjusted to fit within the host parent card; desktop or mobile -->                
                            <v-card v-scroll.self="onScroll" class="scroll" ref="rssViewer" :height="this.cardHeight-(this.isMobile?100:185)" > 
                                <RssFeedViewer :rssFeed="reportData" /> 
                            </v-card>                                                                  
                        </v-flex>                 
                    </v-container>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>  
</template>
<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n          from "@/i18n"  
    import { eventBus }  from '@/main'  // event bus communication for registered components
    // add embedded feed viewer for customizing feed
    import RssFeedViewer from '@/components/views/RssFeedViewer.vue'
    // EDGAR Search Utility - using CIK number  - https://data.sec.gov/rss?cik=320193&count=100
    // iframe - failed using -> const cikUrl = 'https://www.sec.gov/edgar/browse/?CIK=';
    //const cikUrl = 'https://data.sec.gov/rss?cik=';
    // *** use NY Date info for unigue key to sync with server - basic security ***
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone:'America/New_York'}));  

    export default {
        // need to pass ticker in props to sync all child components 
        props: { ticker: { type: String } },
        components: { RssFeedViewer },
        data: () => ({
            locale: i18n.locale,
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),             
            value:  null,
            symbol: "AAPL",
            cikNo:  localStorage.getItem('CikNo'),
            reportData: 'SEC Report Loading ... Please Wait', 
            scrollInvoked: 0,
            cardHeight: 800,
            //---------- SECURITY CHECKS SENT to SERVER -------------------------------*
            xKey:  tNYDate.getFullYear()+(tNYDate.getMonth()+1) + (tNYDate.getDate()),
            x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1) * (tNYDate.getDate()),          
            //-------------------------------------------------------------------------*   
            mode: sessionStorage.getItem('mode'),           
            errors: {}
        }),
        methods: {
            getLatestCikNo() {
                setTimeout( ()=> { this.getFeedReport(); }, 500 );  
            },
            forceRerender() {
               this.componentKey = this.symbol;  
                // get updated number
                this.getLatestCikNo();
            },
            /*******************************************************************
             *  update component symbol from 'emitted' event call from parent
             ******************************************************************/            
            tickerUpdate( ticker ) {
               this.symbol = ticker;
               this.cikNo  = localStorage.getItem( 'CikNo' );
               setTimeout( ()=> { this.forceRerender(); }, 150 );  
               //console.log("Ticker Recvd: "+ticker+" Ticker = "+this.symbol);               
            },
            /**************************************************************************************
             * this method is assigned to each button generated by looping through List1 and List2
             * when clicked it will make an axios call to retrieve the requested Rss feed from
             * the back end servlet which will pull it from the S3 bucket.
             * 
             * ListNo is passed to clear other button list so it is not highlighted/selected
             * no button group is cleared if zero 0 is passed
             *************************************************************************************/      
            getFeedReport() {          
                this.$loading( true ); 
                // clear any previous data - to start on first story
                this.reportData = '';   // https://data.sec.gov/rss?cik=320193&count=100
                let cikNo       = localStorage.getItem( 'CikNo' );
                let ticker      = localStorage.getItem( 'lastSeachedSymbol' );
                // request report from back-end to retrieve selected Rss Feed - pass date calculations to match on server side
                this.axios.get('/secRSS?cik='+cikNo+"&ticker="+ticker,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'mode':this.mode}}).then((response) => {
                        // clear any existing data - reset scroll window position
                        this.scrollInvoked=0;                    
                        //console.log( response.data  ); // <- later comment out
                        //var rssFeedData = response.data;
                        //console.log( rssFeedData );
                        // **** throw error to popup message - invalid symbol ****
                        //if( rssFeedData === null || rssFeedData.length <= 10 ) {
                            // throw new Error('EDGAR RSS Error');
                        //} else {
                            // store feed info to data element 'text' for display - which
                            // is created under a tab section ... reference back to that tab first
                            //   this.$refs[rptName];
                            // assign report to the RssFeedViewer - here
                            this.reportData = response.data;
                            // set scroll back to top
                            this.scrollInvoked=0;
                            // slight delay to show screen - remove any refresh data tries
                            //setTimeout( ()=> { this.$loading(false); sessionStorage.removeItem("refresh"); }, 1000 );
                            // recall to slide-out ads - show ads - if enabled in .env file 
                            //this.showHideAds();                               
                        //}
                        this.$loading( false ); 
                    }).catch( (err) => { 
                        this.$loading(false);
                        var xErr = "<div class='w3-row w3-margin-bottom'>"+    
                                    "<div class='w3-card-4' style='width:100%'>"+
                                    "<header class='w3-container w3-red w3-left-align'>"+
                                    "<h3>Updated SEC Edgar Data Available</h3>"+
                                    "<h6>Downloading Please Wait ...</h6></header>"+
                                    "<div class='w3-container w3-left-align'>"+
                                    "<p></p><p>"+i18n.t('rss_err_msg.action') +"</p>"+
                                    "</div></div></div>";
                            console.log( err );
                        // show user by udating - report data for the view
                        this.reportData = xErr;
                        // reload application - probably due to connection timeout or stale data list
                        // *** check for previous try attempt to refresh in session storage ***
                        var refresh = sessionStorage.getItem("refresh");
                        // loader
                        //setTimeout( ()=> { this.$loading(true); }, 2500 );    
                        // after previous attempt - to reload the list - direct to 'home'
                        if( refresh != null ) {
                            // first remove 'reload flag' from sessionStorage - redirect 'home'
                            sessionStorage.removeItem("refresh");
                            setTimeout( ()=> { this.$loading(true); }, 2500 );   
                            setTimeout( ()=> { window.location.href="/"; }, 3500 ); 
                            //console.log( "refresh found & removed - redirected HOME ... " );
                        } else {
                            // **** try refreshing this page data on a reload  1x only ****
                            this.refreshMsg();                                 
                            // set storage item - 'refresh' before reloading this page
                            sessionStorage.setItem( "refresh", 'true' );
                            //console.log( "refresh SET - reload page .. " );                          
                            setTimeout( ()=> { window.location.reload(); }, 1500 );  
                        }
                    }).finally( ()=> {
                        this.$loading(false);
                    })
            }
        },
        beforeMount() {
           this.$loading(true);
           this.getLatestCikNo();
        },
        mounted() {
            // listen for Ticker Change - from parent component
            eventBus.$on("ticker-update", this.tickerUpdate, 'ticker' );   
            setTimeout( ()=> this.$loading(false), this.symbol=this.ticker, 1500 );   
        },
    }
</script>

<style scoped>
    .scroll {
      overflow-y: scroll
    }
</style>