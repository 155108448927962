<!--   DEFAULT HOME DIALOG -------------------------------------------------------------------
    splash screen - home page with 2 tabs ( tab:1 graphic with rotating points, tab:2 Greed & Fear Chart )  
                    appears only if there are no prior 'bookmarks' - or a corrupted 'bookmarks' is determimed

    tile menu current settings: this block will replace the default home page comppent if there are more than 1
    saved Bookmarks - 'Tile' type of display walking all the locally stored bookmarks for easier access  xs12 sm8 md4 ld2
---------------------------------------------------------------------------------------------->
<template>
  <!-- *** show default home page card if bookmarks are less than 5 *** -->
    <div>
        <!-- bookmarks found - tile menu displayed                           -->
        <!-- tile menu - uses default tiles as base - user can add or remove -->
        <div v-if="hasBookmarks === true" >
            <tileMenu />
        </div>
        <!-- no bookmarks found - default splash screen displayed  -->
        <div v-else >
            <splashScreen />
        </div>
    </div>
</template>

<script>
  import { eventBus } from '@/main'  // event bus communication for registered components
  // import default search splash screen -- removed as default splash screen
  import splashScreen from '@/components/menus/HomePageSearchSplash.vue'
  // home page tile view
  import tileMenu     from '@/components/menus/HomePageTileMenu.vue'

  export default {
    components: { splashScreen, tileMenu },
      data() {
        return {
          // determines on object creation if running on mobile device true or false
          isMobile: ( /Mobile/i.test(navigator.userAgent) ),                  
          screenHeight: window.screen.height,
          screenWidth:  window.screen.width,
          hasBookmarks: false,
          metaInfo: {
              title: "Stock-Insight's Home Page",
              meta: [
                { name: 'robots', content: 'index,follow'},
                { property: 'og:type', content: 'website'}, 
                { property: 'og:site_name', content: 'Stock-Insight.net'},
                { property: 'og:title', content: "Stock-Insight's Home"},                             
                {
                  //vmid: "description",
                  name: "description",
                  content: "Stock-Insight.net 'Maybe We Can Help You Connect The Dots' in Stock News"
                }
              ],
              link: [ { rel: 'canonical', href: '<https://stock-insight.net/>' } ]
          },             
        }
      },
      beforeMount() {
          // show spinner
          this.$loading( true );
          // check for saved bookmarks before init to setup splash pages - default and tiles
          this.checkBookmarks();
      },  
      mounted() {
          // 1 & 1/2 sec delay - then remove loading indicater       
          setTimeout( ()=> this.$loading(false), 1250 );
      },
      methods: {
        clearBookmarks() {
            localStorage.removeItem('bookmarks');
            this.hasBookmarks  = false; 
            // refresh App - to show default splash screen
            const url = new URL( '/', window.location.origin )
            // call to re-check bookmarks from S3 - error will set default bookmarks - start over - in 2 secs
            window.location.href = url.toString();          
        },
        checkBookmarks() {
            /*********************************************************
             * very important to retrieve local storage json string
             * of bookmarks and re-parse for javascript to count
             * correctly the number of actual bookmarks and not the 
             * enclosed array items of those bookmarks ...
             *********************************************************/
              try {
                // get latest bookmark listing
                var userBookmarks = JSON.parse( localStorage.getItem("bookmarks") || "[]" );
                if( userBookmarks && userBookmarks.length < 1 ) {
                    // will show splash screen - no bookmarks
                    this.hasBookmarks  = false;                     
                } else {
                    // *** check for bookmarks with 'tile' property - old bookmarks do not have tiles ***
                    var tileFound = false;
                    for( var key in userBookmarks ) {
                        if( userBookmarks.hasOwnProperty( key ) ) {
                            // if tile element exists - assume new structure - exit
                            if( userBookmarks[key].tile ) {
                                tileFound = true;
                                //console.log("checking keys: "+ userBookmarks[key].tile  )
                            } else {
                                // no Tile def found - remove bookmarks - to start over - check all bookmarks
                                //console.log("Error No Tile for: "+ userBookmarks[key].route.name );
                                tileFound = false;
                                break;
                            }
                        }
                    }
                    if( tileFound !== true ) {
                        // will show splash screen - to start over
                        // old bookmarks or none in local - remove user bookmarks to start with splash screen
                        this.clearBookmarks();
                    } else {
                        // update data block stats - to switch to tile menu screen
                        this.hasBookmarks  = true;
                    }
                }
            } catch( err ) {
                // clear bookmarks for JSON parse error to start over with default list from S3 from splash screen
                this.clearBookmarks();
            }
        }
      }
  }
</script>