<!--
  
    TV Company Profile Widget TAB COMPONENT
    <v-container class="fill-height" :key="this.symbol" > 
-->
<template>
    <v-container class="fill-height" :key="this.symbol" > 
        <v-layout justify-center align-center>    <!-- min-width="225" width="550" max-width="600" -->    
            <v-card id="tvProfile" class="mx-auto my-auto" height="100%" width="100%" hover >
                <v-card-text  class="text--primary ma-0 pa-0">
                    <v-container fill-height class="ma-0 pa-0" >
                        <v-flex fill-height >  
                           <div v-if="isMobile === false">
                                <!-- DESKTOP VIEW -->
                                <v-row no-gutters >
                                    <v-col cols="6">
                                        <v-card height="650" >
                                            <!-- TradingView Widget BEGIN -->
                                            <div class="tradingview-widget-container">
                                            <div class="tradingview-widget-container__widget"></div>
                                            <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                                <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js" async>
                                                    {
                                                        "width": "100%",
                                                        "height": "100%",
                                                        "colorTheme": "light",
                                                        "isTransparent": false,
                                                        "symbol": "{{ this.symbol }}",
                                                        "largeChartUrl": "https://stock-insight.net/symbolchart",
                                                        "locale": "{{ this.locale }}"
                                                    }
                                                </script>
                                            </div>
                                            <!-- TradingView Widget END -->
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card height="650">
                                            <!-- TradingView Widget BEGIN -->
                                            <div class="tradingview-widget-container">
                                            <div class="tradingview-widget-container__widget"></div>
                                            <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                                <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>
                                                    {
                                                        "interval": "1m",
                                                        "width": "100%",
                                                        "isTransparent": false,
                                                        "height": "100%",
                                                        "symbol": "{{ this.symbol }}",
                                                        "showIntervalTabs": true,
                                                        "locale": "{{ this.locale }}",
                                                        "colorTheme": "light",
                                                        "largeChartUrl": "https://stock-insight.net/symbolchart"
                                                    }
                                                </script>
                                            </div>
                                            <!-- TradingView Widget END -->
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- MOBILE SECTION v-model="panel" -->
                            <div v-else>
                                <v-expansion-panels v-model="panel" accordion >
                                    <v-expansion-panel >
                                        <v-expansion-panel-header color="#0D47A1">
                                            <h4 class="white--text">Company Profile</h4>
                                         </v-expansion-panel-header>                                        
                                        <v-expansion-panel-content class="ma-0 pa-0" color="#0D47A1">
                                            <v-card height="580" width="100%">
                                                <!-- TradingView Widget BEGIN -->
                                                <div class="tradingview-widget-container">
                                                <div class="tradingview-widget-container__widget"></div>
                                                <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                                    <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js" async>
                                                        {
                                                            "width": "100%",
                                                            "height": "100%",
                                                            "colorTheme": "light",
                                                            "isTransparent": false,
                                                            "symbol": "{{ this.symbol }}",
                                                            "largeChartUrl": "https://stock-insight.net/symbolchart",
                                                            "locale": "{{ this.locale }}"
                                                        }
                                                    </script>
                                                </div>
                                                <!-- TradingView Widget END -->
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel  >
                                        <v-expansion-panel-header color="#546E7A">
                                            <h4 class="white--text">Analysis Guidance</h4>
                                        </v-expansion-panel-header>                                             
                                        <v-expansion-panel-content class="ma-0 pa-0" color="#546E7A">
                                            <v-card height="580" width="100%">
                                                <!-- TradingView Widget BEGIN -->
                                                <div class="tradingview-widget-container">
                                                <div class="tradingview-widget-container__widget"></div>
                                                <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                                    <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>
                                                        {
                                                            "interval": "1m",
                                                            "width": "100%",
                                                            "isTransparent": false,
                                                            "height": "100%",
                                                            "symbol": "{{ this.symbol }}",
                                                            "showIntervalTabs": true,
                                                            "locale": "{{ this.locale }}",
                                                            "colorTheme": "light",
                                                            "largeChartUrl": "https://stock-insight.net/symbolchart"
                                                        }
                                                    </script>
                                                </div>
                                                <!-- TradingView Widget END -->
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                        </v-flex>  
                    </v-container>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>  
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    import { eventBus } from '@/main'  // event bus communication for registered components

    export default {
        // need to pass ticker in props to sync all child components 
        props: { ticker: { type: String } },
        data: () => ({
            locale: i18n.locale,
            isMobile: ((/Mobile/i.test(navigator.userAgent)) && !(/ipad/i.test(navigator.userAgent))),             
            value: null,
            symbol: "AAPL",
            panel: 0,
            errors: {},
        }),
        methods: {
            forceRerender() {
               this.componentKey = this.symbol;  
            },            
            tickerUpdate( ticker ) {
               // update component symbol from 'emitted' event call from parent
               this.symbol = ticker;
               this.forceRerender();
               //console.log("Ticker Recvd: "+ticker+" Ticker = "+this.symbol);               
            }
         },
        beforeMount() {
            // show loading spinner - just before component is mounted to app
            this.$loading(true);
        },
        mounted() {
            // listen for Ticker Change - from parent component
            eventBus.$on("ticker-update", this.tickerUpdate, 'ticker' );
            // 1.5 sec delay - then remove loading indicater       
            setTimeout( ()=> this.$loading(false), this.tickerUpdate( this.ticker ), 1500 );

        },
    }
</script>

<style>

</style>