
<!-------------------------------------------------------------------------------------

    TradingView Financials
      background-color: #21D4FD;
      background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

      background-color: #FF3CAC;
      background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);

-------------------------------------------------------------------------------------->
<template>
  <!-- IMPORTANT UPDATE  
      'key' to track back-end symbol changes - allowing user to select new ticket from list to 
       to get updated display of Stock Fundimentals from TV   "fa-solid fa-magnifying-glass"
       https://stackoverflow.com/questions/61330855/embedding-an-entire-html-page-into-a-vue-js-template
       [ https://github.com/sweetalert2/sweetalert2/issues/646 ] - popup ?  align-center justify-center
       <v-container class="fill-height  no-print" > 
  -->
  <v-container class="fill-height printable" > 
    <v-layout class="mt-1 mb-5" column>
   <!-- <v-flex row align-center>   -->   
      <!-- min-width="225" width="550" max-width="600" -->
      <!-- DESKTOP SECTION -->
      <div v-if="isMobile === false" >
          <v-card class="mx-auto" height="650" width="75%" hover >
              <v-toolbar color="primary" dark flat >
                <v-row no-gutters dense>
                    <v-col cols="2" class="mt-3">
                        <v-btn @click.prevent="searchStockSymbols"><v-icon left>fa-solid fa-magnifying-glass</v-icon>Search</v-btn>
                    </v-col>
                    <v-col cols="8" class="mt-5 d-flex justify-center">
                        <v-toolbar-title class="pa-0 mx-auto text-h6 font-weight-bold" >[ {{this.symbol}} ]&nbsp;Fundamentals Analysis</v-toolbar-title>
                        <!-- commented out 10.22.23 - does not work with iFrames CORS issues -->
                        <!-- <v-btn small icon @click.prevent="print"><v-icon left>fa-solid fa-print</v-icon></v-btn> -->                    
                    </v-col>
                    <v-col cols="2" class="mt-3">
                        <Bookmark link="Fundamentals"/> 
                    </v-col>
                </v-row>
                <template v-slot:extension>
                   <!-- loop through  -->
                    <v-tabs v-model="tab" background-color="primary" dark>
                        <v-tab v-for="item in items" :key="item.tab" @click.prevent="updateSelectedTab( item.id )">
                             <v-icon left>{{item.icon}}</v-icon>
                                {{ item.tab }}
                        </v-tab>
                    </v-tabs>
                </template>
              </v-toolbar> 
              <v-tabs-items v-model="tab" id="fTabs" >
                  <v-tab-item v-for="item in items" :key="item.tab" >
                      <v-card flat class="ma-0 pa-0">
                          <v-card-text class="ma-0 pa-0">
                              <!-- very important * to pass ticker update props to child to keep all components in sync -->
                              <component id="item.name" :ticker="symbol" :is="item.content" ></component>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>
          </v-card>
      </div>
      <div v-else>
          <!-- MOBILE SECTION -->
          <v-card class="mt-1 flexcard" min-width="95%" width="99%" :height="this.cardHeight" hover >
                <v-toolbar height="75px" dark flat color="#4286f4" >
                    <v-row>
                      <v-col cols="2" class="mt-1">
                          <v-btn icon @click.prevent="searchStockSymbols"><v-icon left>fa-solid fa-magnifying-glass</v-icon></v-btn>
                      </v-col>
                      <v-col cols="8" class="mt-2 d-flex justify-center">
                          <v-toolbar-title class="ma-0 pa-0 mx-auto text-h6 font-weight-bold" >{{this.symbol}}&nbsp;Fundamentals</v-toolbar-title>
                      </v-col>
                      <v-col cols="2" class="mt-3 ml-0">
                          <Bookmark link="Fundamentals"/>
                      </v-col>
                    </v-row>
                </v-toolbar>                     
                <v-row>
                    <v-col class="ml-0" cols="12">
                        <!-- mobile report bar 8 -->
                        <v-btn  color="#84FFFF" block v-if="isMobile === true" @click.stop="drawer = !drawer" >
                            <v-icon left >{{ listIcon }}</v-icon> Available Analysis
                        </v-btn>  
                    </v-col>
                    <!-- for mobile printing - not enabled - CORS issues printing iFrames
                      <v-col class="ml-0" cols="3">                    
                        <v-btn color="#84FFFF" block v-if="isMobile === true" @click.prevent="print" >
                            <v-icon left >fa-solid fa-print</v-icon></v-btn>  
                      </v-col>   
                    -->
                      <!-- *** feed display section - all rss data will be displayed in the RssFeedViewer *** -->  
                      <!-- height adjusted to fit within the host parent card; desktop or mobile --> 
                </v-row>
                <v-row>
                  <v-col cols="12">
                       <!-- default load 1st item in array -->
                       <component v-bind:ticker="symbol" v-bind:is="this.mobileComponent" ></component>
                  </v-col>
                </v-row>               
          </v-card>
          <!-- *** START Left Drawer for small screens - uses tab v-card section to display report *** -->
          <v-navigation-drawer absolute app v-model="drawer" color="blue" theme="dark" class="overflow-y-auto mt-0 pt-0 pb-30 mb-30" temporary >
              <v-row dense no-gutters class="mb-0 pb-0">
                  <v-col class="text-md-center" cols="12">               
                      <h4 class="ml-auto mb-0 pb-0 white--text">{{ symbol }}</h4>  
                  </v-col>
              </v-row>
              <v-divider dark style="height:3px;background-color:aqua" class="ma-0 pa-0" ></v-divider>             
              <v-list nav dense class="ma-0 pa-0">
                <v-list-item-group v-model="group" class="text-left" no-action sub-group>
                  <!-- loop through both feed lists to generate menu for drawer -->
                  <v-list-item v-for="item in items" :key="item.id" @click.prevent="updateMobileComponent( item.id  )">
                      <!-- highlight first report id: 0 as 'active' link -->
                      <div v-if="item.id === 0">
                          <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                            <v-icon id="fIcon" left class="initHighlighted" >{{ item.icon }}</v-icon>
                          </v-list-item-icon>     
                      </div>
                      <div v-else>
                          <v-list-item-icon class="subListIcon pl-0 pr-1 ml-0 mr-1">                
                              <v-icon left >{{ item.icon }}</v-icon> 
                          </v-list-item-icon>     
                      </div>                  
                      <v-list-item-content :style="{'align-items':'left'}">
                          <v-list-item-title id="listFont" class="ListItemClassInActive" >{{ item.tab }}</v-list-item-title>
                      </v-list-item-content>                      
                  </v-list-item> 
                </v-list-item-group>
              </v-list>
              <v-divider dark style="height:3px;background-color:aqua" ></v-divider>              
          </v-navigation-drawer>        
          <!-- *** END Left Drawer Section *** -->   
          <!-- <div class="g-recaptcha" data-sitekey="6LfTyoAoAAAAAP2kvZh1db1bQUl5gWnrOQMKAVa6"></div>  -->       
      </div>
      <!-- RECAPTCHA TOOL 
            <recaptcha ref="recaptcha" @verify="submit"></recaptcha>
      -->
  <!--   </v-flex> -->
    </v-layout>
    <!-- <printIframe msg-title="Print Analysis" msg-text="This will print the currently selected analysis!" />-->
    <!-- hidden until needed, multi search returned list of matches user needs to select 1 :visible="showMultiMatchDialog" -->
    <MultiOptionDialog max-width="75%"  @close="showMultiMatchDialog=false" />
  </v-container>
</template>

<script>
    // listen for and allow broadcasts to components
    import { eventBus } from '@/main'  // event bus communication for registered components

    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    // import jquery to enable mask to ticker search
    //import $ from "jquery"
     
    // bottom ads
    //import ToolsBottomAds from '@/components/ads/ToolsBottomAds.vue';

    // user bookmarking component   ToolsBottomAds, Bookmark 
    import Bookmark           from '@/components/AddToFavorites.vue'    

    // tab components (children) of FinancialsWidget
    import SymbolOverviewTab  from '@/components/widgets/SymbolOverviewWidget.vue'
    import FinancialsTab      from '@/components/widgets/FundamentalsWidget.vue'
    import ProfileTab         from '@/components/widgets/ProfileWidget.vue'
    import EdgarSearchTab     from '@/components/widgets/EdgarCompanySearch.vue'
    import TopStoriesTab      from '@/components/widgets/TopStoriesWidget.vue'

    // ** dialog to show if multiple matches returned from Stock/Company Search ( company name for example )
    import MultiOptionDialog  from '@/components/widgets/tools/MultiOptionDialog.vue'

    // print component
    //import printIframe         from '@/components/printIframe.vue'

    // captcha
    //import Recaptcha           from '@/components/Recaptcha-tool.vue'
    //import VueThreejs          from 'vue-threejs'

    // tradingview console errors
    var expectedOrigin = "https://s3.tradingview.com";
    var iframes;
    // *** use NY Date info for unigue key to sync with server - basic security ***
    var tNYDate = new Date(new Date().toLocaleString('en', {timeZone:'America/New_York'}));  

    export default {
      // declare sub components to use
      components: { SymbolOverviewTab, FinancialsTab, ProfileTab, EdgarSearchTab, TopStoriesTab, Bookmark, MultiOptionDialog },
      data: () => ({
        valid: false,
        tab: null,
        tabName: null,
        group: null,
        drawer: false,         
        locale: i18n.locale,
        symbol: "AAPL",
        cikNo: '',
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        mobileRptBar: i18n.t('rss_mobile_reportbar'),
        listIcon: 'fa-solid fa-list',                     
        value: null,
        cardMinWidth: "85%",
        cardWidth: "85%",
        cardMaxWidth: "95%",
        cardMinHeight:850,
        cardHeight: 900, 
        itemSelected: 0,
        showAdsID : 0,
        hideAdsID : 0,               
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ), 
        multiMatchList: [], 
        items: [
            { id: 0, tab: 'Overview',    content: 'SymbolOverviewTab', name: 'tvOverview',   icon:'fa-solid fa-square-poll-vertical' }, 
            { id: 1, tab: 'Financials',  content: 'FinancialsTab',     name: 'tvFinancials', icon:'fa-solid fa-money-check-dollar' },
            { id: 2, tab: 'Profile',     content: 'ProfileTab',        name: 'tvProfile',    icon:'fa-solid fa-file-contract' }, 
            { id: 3, tab: 'SEC Filings', content: 'EdgarSearchTab',    name: 'edgarSearch',  icon:'fa-solid fa-file-shield' },             
            { id: 4, tab: 'Top News',    content: 'TopStoriesTab',     name: 'tvTopStories', icon:'fa-regular fa-newspaper' },                                           
        ],
        // mobile only
        dotMenu: [ { title: 'Bookmark' },{ title: 'Print' } ],
        // allow mobile to switch components viewed from left drawer by selecting array id
        mobileComponent: null,
        //---------- SECURITY CHECKS SENT to SERVER to execute queries ------------*
        xKey:  tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
        x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
        //-------------------------------------------------------------------------*   
        mode: sessionStorage.getItem('mode'),    // used for Demo Ads - mode               
        errors: {},
      }),
      // user defined methods  <i class="fa-regular fa-newspaper"></i>
      methods: {
        forceRerender() {
           // forces component to reload itself, instead of a complete page reload ... 'cool'
           // data stored from other components in localStorage - update values to stay in sync
           this.symbol = localStorage.getItem( "lastSeachedSymbol" );
           this.cikNo  = localStorage.getItem( "CikNo" );
           // tied to 
           this.componentKey = this.symbol; 

        },
        updateSelection( tkr ) {
           this.symbol = tkr;
           // reload component since all the tabs are different widgets           
           this.forceRerender();
        },
        getCurrentTabName() {
           return this.tab.name;
        },
        updateSelectedTab( id ) {
           this.itemSelected = id;
           //console.log( "Current Tab id: "+id );
           // clear any Ads - if set in .env file
           // close right drawer - if open
           //eventBus.$emit("hide-side-ads","hideSideAds");      
           this.showHideAds();           
        },
        adCleanUp() {
          // close right drawer
          eventBus.$emit("hide-side-ads","hideSideAds");  
          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
        },
        showHideAds() {
          this.adCleanUp();
          var delay = this.time_delay;
          // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
          this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
          //console.log("showAdsID = "+this.showAdsID);    
          delay = delay * 3;
          // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
          this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
          //console.log("shideAdsID = "+this.hideAdsID);    
        },              
        // mobile (only) component selecting --*
        updateMobileComponent( id ) {
          // display loading
          this.$loading(false);
          // set selected id 
          this.itemSelected = id;
          // *** update selected component by passed Id to get actual component ***
          this.mobileComponent = this.items[ this.itemSelected ].content;
          // remove highlighed default mobile selected icon - must be a better way ...
          if( this.isMobile && id != 0 ) {
              var icon = document.getElementById("fIcon");
              icon.classList.remove("initHighlighted");
          }              
          // close drawer after selection --***
          this.drawer = false;  
          // check for Ads
          this.showHideAds();
          setTimeout( ()=> this.$loading(false), 1500 );       
        },
        disablePastDates(val) {
           return val >= new Date( this.startDate ).toISOString().substr(0, 10)
        },
        showDateHelp() {
            // emits global event for component - to open help document from button
            eventBus.$emit("show-date-wiki", "showDateHelp");
        },
        /*********************************************************************************************
         * called by Financials Component to search for CIK # from ticker symbol to pull EDGAR Info
         * 
         *********************************************************************************************/
         getCIKfromTicker( ticker ) {
            //console.log("FYI: Calling CikNo Service host: "+searchForCik );
            this.$loading(true);
            // clear multiMatch List - if any previous searches in case stored
              sessionStorage.removeItem( "multiCompanyMatchList" );
              sessionStorage.removeItem( "tickerSearch" );
            // send request to back-end here ...needed to URI encode ticker if spaces present
            // console.log( '/cikNo?ticker='+encodeURIComponent(ticker.trim()) );
            //let rqt = ticker.replaceAll(' ','_'); // replace any spaces with '_' - parsed on server side - Axios issue with %20 failing
            //const params = { ticker: encodeURIComponent( ticker.trim() ) }
            //const tkr = ticker.trim();
            //const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}`;
            //const xTicker = encodeURIComponent( ticker.trim() );
            this.axios.get( '/cikNo?ticker='+ticker,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'mode':this.mode} }).then((response) => {
                // check if not null .. then how many multible options returned from server ... this section
                if( response.data && response.data.length > 0 ) {
                  // handle 1 record returned matching company name only searches
                  //let jsonData = JSON.parse( response.data );
                  if( response.data.length == 1 ) {
                      try{
                          // split out here to get Ticker and CikNo from selection
                          // return example => { label: "Eagle Point Credit Co Inc.", value: "ECCV:1604174" }
                          this.symbol = response.data[0].value.split(':')[0]; // ticker prefix
                          this.cikNo  = response.data[0].value.split(':')[1]; // Cik # after ':' suffix
                          console.log( "Search: "+ticker+" 1 Match Ticker: "+this.symbol+" Cik: "+this.cikNo );
                          // update localStorage  - to reflect regular search selection
                          localStorage.setItem( "lastSeachedSymbol", this.symbol );
                          // store SEC 'cikNo' in localStorage 
                          localStorage.setItem( "CikNo", this.cikNo ) 
                          // ****** post update to all listening sub components to update themselves ******
                          eventBus.$emit( "ticker-update", this.symbol );  // this call refreshes screen
                      } catch( err ) {
                          this.$swal({ icon: "error", title: "JSON Error", text: "Invalid => [ "+err+" ]", footer: 'The SEC May Have Delisted This Ticker' }); 
                      }
                  } else {
                      // check for multiple Json records returned - here
                      try {
                          // check number of matching companies returned first - limit list to 25 browser memory issues
                          if( response.data.length > 50 ) {
                              this.$swal({ icon: "warning", title: "Matches Exceeds Limit", text: "Too Many Matches For: [ "+ticker+" ]", footer: 'Define a more precise query: # Matches ('+response.data.length+')' }); 
                          } else {
                              // update localStorage multi match list for child to view
                              sessionStorage.setItem( "multiCompanyMatchList", JSON.stringify( response.data ) );
                              // ******* then show dialog for user to select from radio buttons matching list *********
                                    eventBus.$emit("show-multiMatch-dialog", true ); 
                              //***************************************************************************************
                              // pass query to Multi-Match dialog - using sessionStorage
                              sessionStorage.setItem( "tickerSearch", ticker );
                          }
                      } catch( err ) {
                          this.$swal({ icon: "error", title: "JSON Error", text: "Invalid => [ "+err+" ]", footer: 'The SEC May Have Delisted This Ticker' }); 
                      }
                  }
                } else {
                    this.$swal({ icon: "warning", title: "No Matches Found", text: "Ticker Lookup Searched Failed", footer: 'Please, Enter a Valid Ticker or Company Name' }); 
                }
            }).catch( (err) => {
                // display 404 Error dialog if No CIK Number found on back-end call
                this.$loading(false);
                this.$swal.close();
                this.$swal.fire({
                  icon:   "error",
                  title:  "Search - Network Issue",
                  text:   "Ticker Lookup Service Failure, Check Network Connection.",
                  footer: "Wait a bit .. then try again"
                });
                // clear multiMatch List - if any previous searches performed
                sessionStorage.removeItem( "multiCompanyMatchList" );
                console.log("Cik Search Error: "+ err )
            }).finally( ()=> {
                this.$loading(false); // clear hour glass
            })
         },
        /****************************************************************
         * important dialog to change ticker - stored to localStorage
         *  Multiple match list returned from Server query - if any
            [ 
              { "label":"MICROSOFT CORP","value":"MSFT" },
              { "label":"ADVANCED MICRO DEVICES INC","value":"AMD" },
              { "label":"MICRON TECHNOLOGY INC","value":"MU" }
            ]
         * 
         ****************************************************************/
         searchStockSymbols() {
            // clear ads if showing
            this.adCleanUp();
            // search popup - here   
            let bFound = false; let cikNo  = 0;
            // used to pass query to Multi-Match radio dialog box
            sessionStorage.removeItem( 'lastSeachedQuery' );
            this.$swal({
                title: 'Stock Search Tool',
                text:  'Enter Ticker Symbol or Company Name ie: AAPL or Apple',
                id:    'tickerSearch',
                inputAutoFocus   : true,                
                inputAutoTrim    : true,
                input            : 'text',
                inputPlaceholder : 'Ticker or Company Name',
                inputAttributes  : { minlength: '1', maxlength: '35', autocapitalize: 'on', autocorrect: 'off' },  
                inputValidator: (value) => { if( !value ) { return( "Stock Symbol or Company Name Required" ) } else { value.toUpperCase() } },
                showLoaderOnConfirm: true,
                confirmButtonText: 'Search',
                showCancelButton: true,
                footer: 'Exchanges: NYSE NASDAQ AMEX',
              }).then((result) => {
                if( result.value && result.value != "" ) {
                    // console.log( result.value );
                    try {
                        // check for any XSS tags inserted in form - from utils.js
                        if( checkForSpecialChar( result.value ) ) {
                          // ignore if any special characters are found in input
                          // console.log('Ignored XSS Attack: '+ result.value );
                          // close previous popup search dialog - to display error
                          this.$swal.close();
                          this.$swal({ icon: "error", title: "Oops...", text: "Invalid Ticker Symbol!", footer: 'Only Valid Stock Tickers Accepted' });                          
                        } else {
                            let ticker = result.value.toUpperCase()
                            // enter possible ticker submitted by user and search - store search query to session for popup radio matcher
                            sessionStorage.setItem( 'lastSeachedQuery', ticker );
                            // perform search after getting input from user ... here
                             this.getCIKfromTicker( ticker );
                        }
                    } catch(err) { 
                          console.log( err ); 
                    } finally {
                        // 1 sec delay - then remove loading indicater to clear screen      
                        setTimeout( ()=> this.$loading(false), 1000 );
                    }
                 }
                // reshow Ads if enabled ..
                this.showHideAds();
             })
        },
      },
      // life-cycle - looks for mount
      beforeMount() {
          // show loading spinner - just before component is mounted to app
          this.$loading(true);
          
          // send event to parent (AppBar.vue) to close Ads right drawer - if any
          eventBus.$emit("hide-side-ads","hideSideAds");  

          // stop any timeout events by assigned ID - before creating any new ones
          clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );   

          // check for last seached ticker to overwrite default AAPL
          var lastSeachedSymbol = localStorage.getItem("lastSeachedSymbol");
          if( lastSeachedSymbol && lastSeachedSymbol != "" && lastSeachedSymbol != null ) {
              this.symbol = lastSeachedSymbol;
          } else {
              // default Ticker Symbol  - AAPL - if empty
              localStorage.setItem("lastSeachedSymbol","AAPL")
              // default Cik number     - for apple 
              localStorage.setItem( "CikNo", "320193" );
          }
      },      
      mounted() {
        // update  selected id 0 component
        this.mobileComponent = this.items[ this.itemSelected ].content;

        // 1.5 sec delay - then remove loading indicater       
        setTimeout( ()=> this.$loading(false), 1750 );

        // check for setting - time out is set in .env file for ads 
        if( this.time_delay <= 0 ) { this.time_delay = 2500; }

        // show ads - if enabled in .env file 
        this.showHideAds();
  
        // *** TradingView Widget Error Handler  ***
        window.addEventListener("message", (e) => {
        if( e.origin !== expectedOrigin ) return;
            const sourceIframe = iframes.find( (iframe) => iframe.contentWindow === e.source, );
            console.log(sourceIframe);
        })          
      },
      beforeUnmount() {
        // send event to parent (AppBar.vue) to close Ads right drawer - if any
        eventBus.$off( "hide-side-ads","hideSideAds" );  
        // stop any timeout events by assigned ID - before moving on to different component
        clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );       
      }     
    }
</script>

<style scoped>

.input.swal2-input  { text-transform:uppercase; }
#tickerSearch { text-transform:uppercase;  }
.v-tab {
    letter-spacing: normal !important;
    display: left !important;
    margin-left: 0px;
    flex-direction: row;
}
 .v-tab--active {
    background-color:rgba(116, 219, 185, 0.3); 
 }
 .v-toolbar__extension {
    position: absolute;
    left: 1px;
 }
 .v-list-item__content {
     text-align: left;
     color: white; 
     font-weight: bold;     
 }
 .v-list-item__title {
     padding-left: 10px;
     font-weight: bold;        
 }
  
  .aa {
    background: rgb(25, 0, 255) !important;
  }
  .ListItemClassActive {
    color: #0556f9;
  }
  .ListItemClassInActive {
    color: #f1f8fa;
   }
  .v-list-item__icon v-list-group__header__prepend-icon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  } 
  .v-list-item--active .subListIcon .v-icon {
     background-color: lightgreen;
  }
  .v-list-item--active .v-list-item__title {
     color: rgb(253, 250, 250);
     font-weight: bold;
  } 
  .subListIcon {
    font-size: 16px;
    width: 40px;    
  }  
  .subListItem {
    font-size: 18px;
  }   
  .initHighlighted {
    background-color: lightgreen;
  }   
  #listFont {
     font-size: 16px;
     padding-top: 5px;
  }     
</style>