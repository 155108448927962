/*
   External File that is imported into the main desktop and left App drawer menu items

   and changes here will change the desktop as well as mobile menu items since they both share these data elements

   All the routes (case sensitive) match routes defined in the /route/index.js file - using component created

   https://cointelegraph.com/rss
   https://cointelegraph.com/rss-feeds

   tile: added to support new home page defined by bookmarks

*/

import i18n from "@/i18n"
    const baseUrl   = "/rs/utils/document?file=tiles/";
    const menuItems = [
        { id: '0ns',  title: "NasdaqTrader Alerts",             group: 'Latest News', icon: 'mdi-alpha-a-circle', route:({name: 'NasdaqTraderAlerts'}), tile: baseUrl+"NasdaqAlertsRss.png" }, 
        { id: '1ns',  title: i18n.t("nav_news.CNBC_news"),      group: 'Latest News', icon: 'mdi-alpha-c-circle', route:({name: 'cnbcNews'}),           tile: baseUrl+"CnbcNewsRss.png" }, 
        { id: '8ns',  title: i18n.t("nav_news.dqe_news_feed"),  group: 'Latest News', icon: 'mdi-alpha-d-circle', route:({name: 'DarqubeNews'}),        tile: baseUrl+"DarqubeNewsRss.png" },                
        { id: '2ns',  title: i18n.t("nav_news.Economist_News"), group: 'Latest News', icon: 'mdi-alpha-e-circle', route:({name: 'Economist_News'}),     tile: baseUrl+"EconomistRss.png" },                 
        { id: '3ns',  title: i18n.t("nav_news.FED_news"),       group: 'Latest News', icon: 'mdi-alpha-f-circle', route:({name: 'FED_News'}),           tile: baseUrl+"USFedRss.png" }, 
        { id: '3ans', title: "Investing.com",                   group: 'Latest News', icon: 'mdi-alpha-i-circle', route:({name: 'InvestingDC'}),        tile: baseUrl+"InvestingDotComRss.png" },         
        { id: '4ns',  title: i18n.t("nav_news.market_watch"),   group: 'Latest News', icon: 'mdi-alpha-m-circle', route:({name: 'marketWatchNews'}),    tile: baseUrl+"MarketWatchRss.png" },  
        { id: '5ns',  title: "Nasdaq News",                     group: 'Latest News', icon: 'mdi-alpha-n-circle', route:({name: 'NasdaqRssFeeds'}),     tile: baseUrl+"NasdaqNewsRss.png" },      
        { id: '7ns',  title: i18n.t("nav_news.politico"),       group: 'Latest News', icon: 'mdi-alpha-p-circle', route:({name: 'politicoNews'}),       tile: baseUrl+"PoliticoRss.png" },  
        { id: '9ns',  title: "Crypto News",                     group: 'Latest News', icon: 'mdi-alpha-r-circle', route:({name: 'Crypto_News'}),        tile: baseUrl+"CrytoNewsRss.png" },  
        { id: '10ns', title: "TradingView Snaps",               group: 'Latest News', icon: 'mdi-alpha-s-circle', route:({name: 'TradingViewSnaps'}),   tile: baseUrl+"TradingViewNewsRss.png" }, 
// Removed Reuters from Menu - requires paid subscription 11.22.24 - PM
//        { id: '11ns', title: "Reuters News",                    group: 'Latest News', icon: 'mdi-alpha-t-circle', route:({name: 'ReutersNews'}),        tile: baseUrl+"ReutersNewsRss.png" },                
        { id: '12ns', title: i18n.t("nav_news.SEC_news"),       group: 'Latest News', icon: 'mdi-alpha-u-circle', route:({name: 'SEC_News'}),           tile: baseUrl+"USSecNewsRss.png" },  
        { id: '13ns', title: i18n.t("nav_news.WSJ_news"),       group: 'Latest News', icon: 'mdi-alpha-w-circle', route:({name: 'WSJ_News'}),           tile: baseUrl+"WSJNewsRss.png" },  
   // trading economics is no longer providing RSS Data - need an API - Paid $2000 / yr
   //     { id: '14ns', title: i18n.t("nav_news.trading_economics"), icon: 'mdi-alpha-x-circle', route:({name: 'tradingEconomicsNews'}) },  
        { id: '15ns', title: i18n.t("nav_news.NYT_news"),       group: 'Latest News', icon: 'mdi-alpha-y-circle', route:({name: 'NYT_News'}),           tile: baseUrl+"NYTNewsRss.png" }, 
        { id: '17ns', title: "Trending Views",                  group: 'Latest News', icon: 'mdi-alpha-z-circle', route:({name: 'RedditViews'}),        tile: baseUrl+"RedditNewsRss.png" },            
   ];

export default menuItems