
<!-- -------------------------------------------------------------------

   Multi Option Dialog Box - designed to handle multiple matches
   // sample list returned from server - passed by parent object
[ 
  { "label":"MICROSOFT CORP","value":"MSFT" },
  { "label":"ADVANCED MICRO DEVICES INC","value":"AMD" },
  { "label":"MICRON TECHNOLOGY INC","value":"MU" },
  { "label":"MICROSTRATEGY Inc","value":"MSTR" }
]

----------------------------------------------------------------------->
<template>
    <v-row justify="center">
      <v-dialog v-model="showDialog" scrollable max-width="400px" >
        <v-card>
          <v-card-title class="justify-center text-h5 blue lighten-2">SEC Company Matches</v-card-title>
          <v-card-subtitle class="justify-center mt-3"><b>Query</b>: [<b>{{ query }}</b>]</v-card-subtitle>          
          <v-card-text style="height: 350px;">
            <v-radio-group v-model="ticker_cikNo" class="small-radio" column >
                <!-- on the back-end the ticker and Cik # are paired in each array element - for the front-end to parse out
                    like this: { "label":"MICROSTRATEGY Inc","value":"MSTR:12345" }
                       As you can see in this loop it grabs [0] for displaying, and the Cik # [1] stored in value when 
                         user selects a company from the radio buttons
                  // query 'eagle' returned matches from the servlet like this, then split accordingly:       
                         {label: "Eagle Point Credit Co Inc.", value: "ECCV:1604174"}
                         {label: "Eagle Point Credit Co Inc.", value: "ECCF:1604174"}
                         {label: "Eagle Point Credit Co Inc.", value: "ECCW:1604174"}
                -->

                    <v-radio v-for ="item in multiMatchList" 
                                          :key   ="item"
                                          :label ="'['+item.value.split(':')[0]+']: '+item.label"
                                          :value ="item.value" >
                    </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-1 mb-5">
            <v-btn color="blue lighten-3" @click="selectTicker" :disabled="!ticker_cikNo" >Select</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red lighten-2" text @click="showMatchDialog( false )" >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
  // listen for and allow broadcasts to components
  import { eventBus } from '@/main'  // event bus communication for registered components

  export default {
      data () {
        return {
            showDialog: false,
            ticker_cikNo: "",
            ticker: "",
            cikNo:  "",
            multiMatchList: sessionStorage.getItem( "multiCompanyMatchList" ),
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
            query: sessionStorage.getItem( 'lastSeachedQuery' ) // subtitle ticker symbol display        
        }
      },
      mounted() {
         // *** listen for Search Ticker - from parent component - [ true or false ] passed
         eventBus.$on( "show-multiMatch-dialog", this.showMatchDialog ); 
      },
      methods: {
         // user pressed select button
         selectTicker() {
              // split out here to get Ticker and CikNo from selection
              this.ticker = this.ticker_cikNo.split(':')[0];
              this.cikNo  = this.ticker_cikNo.split(':')[1];
               // update localStorage  - to reflect regular search
              localStorage.setItem( "lastSeachedSymbol", this.ticker );
              // store SEC 'cikNo' in localStorage 
              localStorage.setItem( "CikNo", this.cikNo ); 
              // ****** post update to all listening sub components to update themselves ******
              eventBus.$emit( "ticker-update", this.ticker ); // this call refreshes screen
              this.showMatchDialog( false );                   // close multi match dialog   
              // clear multiMatch List - if any previous searches
              sessionStorage.removeItem( "multiCompanyMatchList" );  
              // *** force window reload - to sync title ticker with selection - temp work-a-round ***
                  location.reload();
             
              //console.log( "User Selected this Company from Multi-List -> ticker: "+this.ticker+" cik#: "+this.cikNo );
         },
         // passed back from event bus from parent when more than 1 ticker returned
         showMatchDialog( bVisible ) {
            if( bVisible ) {
              // get query from session for display title
              this.query = sessionStorage.getItem( 'lastSeachedQuery' )
              // update dialog list from session before dialog is shown
              if( sessionStorage.getItem( "multiCompanyMatchList") != null ) {
                 this.multiMatchList = JSON.parse( sessionStorage.getItem( "multiCompanyMatchList") );
              }
              // Here you would put something to happen when dialog opens up
              //console.log("Dialog message event bus rcvd to Open!")              
            } else { 
              // remove list from session - to reset for next query
              sessionStorage.removeItem( "multiCompanyMatchList" )
              sessionStorage.removeItem( "tickerSearch" )
              //console.log("Dialog message event bus rcvd to close!")
            }
            // **** update data block - to hide or show dialog ****
            this.showDialog = bVisible;
         },
    }
  }
</script>

<!-- removed 'scope' to apply css to radio buttons -->
<style>

.v-label theme--light {
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif
}

</style>

