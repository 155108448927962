<!--
  This is the parent component of the application. 
  
  The 'AppBar' and 'FooterBar' are imported here from the components folder and declared in the export default section.

  You'll see that those components are inserted in the 'main' section since they'll be used 'globally' through out the application.

  The 'router' will add components selected by the user ie: 'Calls' and 'Puts' through the 'tab' menu.
  
  All other components are added here  

  style="background-color: #011c40;background-image: linear-gradient(180deg,  #011c40 50%,  rgb(26, 65, 115) 100%);
  background-size: cover;"   <router-view :key="$route.fullPath"></router-view>
-->
<template>
    <v-app id="app" >
        <AppBar/>  
        <v-main>
          <!-- ADS Right Drawer class="mt-0 pt-0" fill-height -->
          <v-navigation-drawer app hide-overlay right v-model="rDrawer" stateless no-click-animation :width="this.rDrawerSize" flat color="transparent" temporary >                             
              <AppBarRightDrawer/>
          </v-navigation-drawer>    
          <!-- end Ad Right Drawer -->
          <router-view />
        </v-main>          
        <!-- *** network restored notice *** applies across all tabs -->
        <v-snackbar v-model="snackbar" :timeout="timeout" rounded="pill" color="green" elevation="24">
              {{ snackbarText }}
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>  
        <TickerFeed/>
        <FooterBar/>                             
    </v-app>      
</template>

<script>
    import { eventBus }      from '@/main'  // event bus communication for registered components
    import AppBar            from '@/components/AppBar.vue'
    import FooterBar         from '@/components/FooterBar.vue'
    import i18n              from "@/i18n"
    // utility method to get browser settings ----*
    import getBrowserLocale  from "@/assets/get-browser-locale.js"
    //import supportedLocales from "@/config/supported-locales"
    import AppBarRightDrawer from "@/components/ads/AppBarRightDrawerMenu.vue" 
    import TickerFeed        from '@/components/views/tvLiveTickerTape.vue'
    // track query param to set Ad Mode Demo - to display demo ads
    let mode; 
    export default {
      name: 'AppView',
      components: { AppBar, TickerFeed, AppBarRightDrawer, FooterBar },
      metaInfo: {
          title: "Stock-Insight's Stock Tools, Charts & News",
          meta: [
            { name: 'robots', content: 'index,follow'},
            { property: 'og:type', content: 'website'}, 
            { property: 'og:site_name', content: 'www.Stock-Insight.net'},
            { property: 'og:title', content: "Stock-Insight's Your Source for Up-to-date Information"},                             
            {
              vmid: "description",
              name: "description",
              content: "Make informed financial decisions. Get the latest news, tools, and data at your fingertips with Stock-Insight.net"
            }
          ],
          link: [ { rel: 'canonical', href: 'https://stock-insight.net' } ]
      },               
      data: () => ({
        locale: i18n.locale,
        online: false,
        snackbar: false,
        timeout: 10000,
        color: '#F1F6F9',
        rDrawer: false,
        rDrawerSize: 300,  // default size - 
        time_delay: Number( process.env.VUE_APP_ADS_DELAY ),  
        timeout:    Number( process.env.VUE_APP_ADS_TIMEOUT ),                      
        screenHeight: screen.height,
        screenWidth:  screen.width,     
        snackbarText: 'Network Connection Restored.',
        // check if running on mobile device - except iPad
        isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
        showAds: process.env.VUE_APP_ADS,   // check if Ads are enabled from .env file   
        showAdsID : 0,
        hideAdsID : 0,               
      }),      
      methods: {
          showSideAds() {
            // show drawer
            setTimeout( ()=> this.rDrawer=true, 250 );                   
          },
          hideSideAds() {
            // called by AppBarRightDrawerMenu to hide adds
            this.rDrawer=false;     
          }, 
          adCleanUp() {
            // close right drawer
            eventBus.$emit("hide-side-ads","hideSideAds");  
            // stop any timeout events by assigned ID - before creating any new ones
            clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );
          },
          showHideAds() {
            this.adCleanUp();
            var delay = this.time_delay;
            // show ads - if enabled in .env file - defined in BottomSheetAds component - record id for clearing
            this.showAdsID = setTimeout( ()=> eventBus.$emit("show-side-ads","showSideAds"), delay );  // record timeout ID -  for clearing
            //console.log("showAdsID = "+this.showAdsID);    
            delay = delay * 3;
            // send event to parent (AppBar.vue) to close Ads right drawer x3 delay
            this.hideAdsID = setTimeout( ()=> eventBus.$emit("hide-side-ads","hideSideAds"), delay );   // record timeout ID - for clearing  
            //console.log("shideAdsID = "+this.hideAdsID);    
          },                  
          // user new browser session - retieve data cookie if previously set from running a query
          getCallInfoCookie() {
             // store data back to data store which will be reteived when 'call' component loads
             try {
              this.$store.commit('CALL_EXPIRATION',  this.$cookies.get('callInfo').call_expiration);
              this.$store.commit('CALL_CONTRACTS',   this.$cookies.get('callInfo').call_contracts); 
              this.$store.commit('CALL_STRIKEPRICE', this.$cookies.get('callInfo').call_strikePrice); 
              this.$store.commit('CALL_OPTIONPRICE', this.$cookies.get('callInfo').call_optionPrice);   
              this.$store.commit('CALL_CURRENTPRICE', this.$cookies.get('callInfo').call_currentPrice); 
              this.$store.commit('CALL_EXPECTEDSTOCKPRICE', this.$cookies.get('callInfo').call_expectedStockPrice);  
            } catch(err) { console.log('No Call Data') }                                                                    
          },
          getPutsInfoCookie() {
            // store data back to data store which will be retrieved when 'put' component loads
            try {
              this.$store.commit('PUTS_EXPIRATION',  this.$cookies.get('putsInfo').puts_expiration);
              this.$store.commit('PUTS_CONTRACTS',   this.$cookies.get('putsInfo').puts_contracts); 
              this.$store.commit('PUTS_STRIKEPRICE', this.$cookies.get('putsInfo').puts_strikePrice); 
              this.$store.commit('PUTS_OPTIONPRICE', this.$cookies.get('putsInfo').puts_optionPrice);   
              this.$store.commit('PUTS_CURRENTPRICE', this.$cookies.get('putsInfo').puts_currentPrice); 
              this.$store.commit('PUTS_EXPECTEDSTOCKPRICE', this.$cookies.get('putsInfo').puts_expectedStockPrice); 
            } catch(err) { console.log('No Puts Data') }
          },
          getMarginInfoCookie() {
            try {
              this.$store.commit('MARGIN_DAYS_BORROWED', this.$cookies.get('marginInfo').margin_days_borrowed);    
              this.$store.commit('MARGIN_AMOUNT_BORROWED', this.$cookies.get('marginInfo').margin_amount_borrowed); 
              this.$store.commit('MARGIN_RATE', this.$cookies.get('marginInfo').margin_rate);  
            } catch(err) { console.log('No Margin Data')}                             
          },
          // *** look for any network changes - may stop data queries or other issues ***
          chkNetwork() {
            // *** record browser navigator network status - it knows when it's offline ***
            this.online = navigator.onLine; 
            // display message if sudden loss of network or still offline
            if( this.online === false ) {
               this.offlinePopup();
               console.log("Network Status: => "+this.online );                              
            }
          },        
          offlinePopup() {
            this.$swal({
                position: 'center',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "Ok",
                //denyButtonText: i18n.t('terms_init_popup_notice.denyBtnTxt'),
                icon: 'error',
                title: 'Offline Network Error',
                text: 'Please Check Your Network Settings.',
                footer: 'You are currently offline, live data queries will fail',
              }).then((result) => {
                  if(result.isConfirmed) { console.log("Checking..."); }
                  // check network status again - loop until fixed ???
                  if( this.online === true ) {
                    // show restored message snackbar
                    this.snackbar = true;
                  } else {
                    this.chkNetwork();
                  }
              })
          }, 
      },    
      //  happens just before the component is shown on the screen
      beforeMount() {
        /*********************************************************************************
         *  important start up call - to check and set bookmarks
         *  js/utils.js method call - check for existing bookmarks before tile menu loads
         *********************************************************************************/
         //checkForBookmarks();

        //*** set session storage which stops ads from displaying until init display messages finish ***
        localStorage.setItem( 'init', true );

        // make sure stored running device type
        this.isMobile = ( (/Mobile/i.test(navigator.userAgent)) )       
        
        // redefine RightDrawer size to device type
        this.rDrawerSize =  ( (this.isMobile || this.screenWidth <= 1180) ? 250 : 450 )

        // store is session for other components reference
        sessionStorage.setItem( 'isMobile', this.isMobile );
        
        // display program mode - developent, testing, or production settings in .env file
        //console.log( "i18n Active locale: ", i18n.locale );
        var loc = getBrowserLocale({ countryCodeOnly: true });
        // *** make sure i18n is switched to user browser locale settings ***
          this.$i18n.locale = loc;
          // update data element
          this.locale = loc;          
          //console.log( "Browser Active locale determined by browser settings: ", loc );

        // *** retrieve data previously set in cookie on call reports when app restarts
        this.getCallInfoCookie();

        // *** retrieve data previously set in cookie on put reports when app restarts
        this.getPutsInfoCookie();

        // *** retrieve data previously set in cookie on put reports when app restarts
        this.getMarginInfoCookie();  

        // send event to parent (AppBar.vue) to close Ads right drawer - if any
        eventBus.$emit("hide-side-ads","hideSideAds");  

        // stop any timeout events by assigned ID - before creating any new ones
        clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );           

      },
      mounted() {
        // *** setup network listeners ***
        window.addEventListener('online',  (e) => { this.chkNetwork(e) });
        window.addEventListener('offline', (e) => { this.chkNetwork(e) });
        
        // *** init setup - in case they are clicking on other tabs while offline
        if( this.chkNetwork() && this.online !== true ) {
           this.offlinePopup();
        } else {
          // ads here after checking online status          
          // register event to show right side draw ads
          // show ads - if enabled in .env file - for Vue build          
          if( this.showAds && this.showAds === 'true' ) {
               // register event to show right side draw ads if defined as [ VUE_APP_ADS=true ] in .env file
               eventBus.$on("show-side-ads", this.showSideAds); 
               // hide event call
               eventBus.$on("hide-side-ads", this.hideSideAds); 
               console.log('show ads = '    +this.showAds);  
          } else {
              // **** ADDED Functionality to DEMO How Ads would look like in this WebApp  03-19-24 PM ******
              // ******* check for Ad Mode passed query string - /?demo - ignored by router *******
              let sMode = sessionStorage.getItem('mode');  // check for session setting - if any
              let qStr  = window.location.search;          // check for query string param
               if( (sMode && sMode === 'demo') || (qStr && qStr.substring(1) === 'demo' ) ) {
                    // store temp session - to display Ads as a Demo
                    sessionStorage.setItem( "mode", 'demo' );
                    // set flag to 'true' to show adds in demo mode
                    this.showAds = true;
                    // register event to show right side draw ads if defined as [ VUE_APP_ADS=true ] in .env file
                    eventBus.$on("show-side-ads", this.showSideAds); 
                    // hide event call
                    eventBus.$on("hide-side-ads", this.hideSideAds); 
                    console.log( "Query MODE String Passed/Stored - User Session : "+ sessionStorage.getItem('mode') );
                    // finally show ads - if passed from query string or stored in user sessions object
                    this.showHideAds();

              }            
          }
        }
      },
      beforeUmount() {
        // clean up - remove listeners - switching to other tab or exiting
        window.removeEventListener('online',  (e) => { this.chkNetwork(e) }); 
        window.removeEventListener('offline', (e) => { this.chkNetwork(e) });
        // send event to parent (AppBar.vue) to close Ads right drawer - if any
        eventBus.$off("hide-side-ads","hideSideAds");  
        // stop any timeout events by assigned ID - before moving on to different component
        clearTimeout( this.showAdsID ); clearTimeout( this.hideAdsID );               
      },      
    }   
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-image: linear-gradient(to bottom right,rgba(132, 191, 240, 0.85), rgba(101, 103, 190, 0.25));
    //color: #3c7abd;
    //background-color: blue ;
    //background: url('@/assets/business-bak_1920.jpg');
    //no-repeat: center center fixed !important;
    background-size: cover;    
  }

  nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
    body {
      background-color: rgb(5, 93, 161);
    } 
  }
  .v-navigation-drawer {
      /*background: transparent !important*/
      margin-top: 5;
      margin-left: 3;
      margin-right: 3;
      padding-left: 0;
      padding-right: 0;
  }
</style>
