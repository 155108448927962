<!--
  
    TV Symbol Overview Widget TAB COMPONENT
    <v-container class="fill-height" :key="this.symbol" > 
-->
<template>
    <v-container class="fill-height" :key="this.symbol" > 
        <v-layout justify-center align-center>    <!-- min-width="225" width="550" max-width="600" -->    
            <v-card  id="tvOverview" class="mx-auto my-auto" height="100%" width="100%" hover >
                <v-card-text class="text--primary ma-0 pa-0">
                    <v-container fill-height class="ma-0 pa-0" >
                        <v-flex fill-height >  
                            <div v-if="isMobile">
                                <!-- MOBILE -->
                                <v-card height="735" >                               
                                    <!-- TradingView Widget BEGIN -->
                                    <div class="tradingview-widget-container">
                                    <div class="tradingview-widget-container__widget"></div>
                                    <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                        <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js" async>
                                            {
                                                "symbols": [ [ "{{ this.symbol }}" ] ],
                                                "chartOnly": false,
                                                "width": "100%",
                                                "height": "100%",
                                                "locale": "{{ this.locale }}",
                                                "colorTheme": "light",
                                                "autosize": true,
                                                "showVolume": false,
                                                "showMA": false,
                                                "hideDateRanges": false,
                                                "hideMarketStatus": false,
                                                "hideSymbolLogo": false,
                                                "scalePosition": "right",
                                                "scaleMode": "Normal",
                                                "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
                                                "fontSize": "10",
                                                "noTimeScale": false,
                                                "valuesTracking": "1",
                                                "changeMode": "price-and-percent",
                                                "chartType": "area",
                                                "lineWidth": 2,
                                                "lineType": 0,
                                                "dateRanges": [ "1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M" ]
                                            }
                                        </script>
                                    </div>
                                    <!-- TradingView Widget END -->
                                </v-card>
                            </div>
                            <div v-else>
                                <!-- DESKTOP -->
                                <v-card height="655" >                               
                                    <!-- TradingView Widget BEGIN -->
                                    <div class="tradingview-widget-container">
                                    <div class="tradingview-widget-container__widget"></div>
                                    <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                        <script type="application/javascript" defer src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js" async>
                                            {
                                                "symbols": [ [ "{{ this.symbol }}" ] ],
                                                "chartOnly": false,
                                                "width": "100%",
                                                "height": "100%",
                                                "locale": "{{ this.locale }}",
                                                "colorTheme": "light",
                                                "autosize": true,
                                                "showVolume": false,
                                                "showMA": false,
                                                "hideDateRanges": false,
                                                "hideMarketStatus": false,
                                                "hideSymbolLogo": false,
                                                "scalePosition": "right",
                                                "scaleMode": "Normal",
                                                "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
                                                "fontSize": "10",
                                                "noTimeScale": false,
                                                "valuesTracking": "1",
                                                "changeMode": "price-and-percent",
                                                "chartType": "area",
                                                "lineWidth": 2,
                                                "lineType": 0,
                                                "dateRanges": [ "1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M" ]
                                            }
                                        </script>
                                    </div>
                                    <!-- TradingView Widget END -->
                                </v-card>
                            </div>
                        </v-flex>                 
                    </v-container>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>  
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n from "@/i18n"  

    import { eventBus } from '@/main'  // event bus communication for registered components

    export default {
        // need to pass ticker in props to sync all child components 
        props: { ticker: { type: String } },
        data: () => ({
            locale: i18n.locale,
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),             
            value: null,
            symbol: "AAPL",
            errors: {}
        }),
        methods: {
            forceRerender() {
               this.componentKey = this.symbol;  
            },            
            tickerUpdate( ticker ) {
               // update component symbol from 'emitted' event call from parent
               this.symbol = ticker;
               this.forceRerender();
               //console.log("Ticker Recvd: "+ticker+" Ticker = "+this.symbol);               
            },
        },
        beforeMount() {
            this.$loading(true);
        },
        mounted() {
            // listen for Ticker Change - from parent component
            eventBus.$on("ticker-update", this.tickerUpdate, 'ticker' );   
            setTimeout( ()=> this.$loading(false), this.tickerUpdate( this.ticker ), 1000 );  
        },
    }
</script>

<style>

</style>